import React from 'react';
import { Helmet } from 'react-helmet';
import CarolinaImage from './Carolina.png';
import SugarPlum from './Sugar-Plum.png';

function DonationPage() {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-6">
      <Helmet>
        <script src="https://www.gofundme.com/static/js/widget.js"></script> {/* GoFundMe script loader */}
      </Helmet>

      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        {/* Heading */}
        <h1 className="text-3xl font-bold text-black text-center mb-6">
          Support the Humane Society of Central Illinois
        </h1>

        {/* Main Donation Description */}
        <p className="text-lg text-gray-700 text-center mb-8">
          The Humane Society of Central Illinois is a 501(c)(3) Not-For-Profit organization. They rely on animal lovers' help to take care of our pets.
          You can help by donating items from the wish list or supporting them with a monetary donation!
        </p>

        {/* GoFundMe Embed */}
        <div className="mt-6 mb-10">
          <iframe 
            src="https://www.gofundme.com/f/make-a-pawsitive-impact-on-the-humane-society/widget/medium/"
            width="100%"
            height="250px"
            frameBorder="0"
            scrolling="no"
            title="GoFundMe"
            className="rounded-lg shadow-md"
          ></iframe>
        </div>

        {/* Side-by-side section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
          {/* Column 1 */}
          <div className="bg-grey-100 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold text-black mb-4">Why Donate?</h2>
            <p className="text-gray-700">
              Your donations provide essential items for animals in our care, including food, medical care, and supplies to make their stay more comfortable.
            </p>
            <p className="mt-4 text-gray-700">
              Every little bit helps us provide a safe and loving environment for these pets until they find their forever homes.
            </p>
          </div>

          {/* Column 2 */}
          <div className="flex justify-center">
          <img
              src={CarolinaImage}
              alt="Carolina"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* More side-by-side content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Column 2 */}
                    <div className="bg-grey-100 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold text-black mb-4">How You Can Help</h2>
            <p className="text-gray-700">
              There are several ways to help the Humane Society. You can donate essential supplies such as food, cleaning products, and bedding.
            </p>
            <p className="mt-4 text-gray-700">
              If you'd prefer, you can also make a monetary donation through our GoFundMe page to directly support our ongoing efforts to rescue and care for animals.
            </p>
          </div>
          {/* Column 1 */}
          <div className="flex justify-center">
          <img
              src={SugarPlum}
              alt="Sugar Plum"
              className="w-full h-auto object-cover"
            />
          </div>


        </div>
      </div>
    </div>
  );
}

export default DonationPage;
