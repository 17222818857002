// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet';

// function HomePage() {
//   const calculateTimeLeft = () => {
//     const eventDate = new Date('2024-10-15T15:00:00');
//     const now = new Date();
//     const difference = eventDate - now;

//     let timeLeft = {};
//     if (difference > 0) {
//       timeLeft = {
//         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         minutes: Math.floor((difference / 1000 / 60) % 60),
//         seconds: Math.floor((difference / 1000) % 60),
//       };
//     }
//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);
//     return () => clearTimeout(timer);
//   }, [timeLeft]);

//   return (
//     <div className="bg-white min-h-screen p-8">
//       <Helmet>
//         <script src="https://www.gofundme.com/static/js/widget.js"></script>
//       </Helmet>
      
//       <h1 className="text-5xl font-bold text-center text-gray-800 mb-12">
//         Welcome to Pawsitive Impact!
//       </h1>

//       {/* Section 1: Introduction */}
//       <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
//         <div>
//           <h2 className="text-3xl font-semibold text-gray-800 mb-4">
//             Who Are We?
//           </h2>
//           <p className="text-lg text-gray-700 leading-relaxed">
//             We're a group of students at ISU working with the Humane Society to support their needs and spread awareness about local pets that need homes. Every donation goes directly to animals in need. Please consider helping in any way you can!
//           </p>
//         </div>
//         <div className="rounded-lg shadow-md overflow-hidden">
//           <img
//             src="https://www.hscipets.org/images/puppy.jpg"
//             alt="Adoptable Puppy"
//             className="w-full object-cover h-full"
//           />
//         </div>
//       </div>

//       {/* Section 2: Supply Drives */}
//       <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
//         <div className="rounded-lg shadow-md overflow-hidden">
//           <img
//             src="https://www.hscipets.org/images/kitten.jpg"
//             alt="Adoptable Kitten"
//             className="w-full object-cover h-full"
//           />
//         </div>
//         <div>
//           <h2 className="text-3xl font-semibold text-gray-800 mb-4">
//             How You Can Help
//           </h2>
//           <p className="text-lg text-gray-700 leading-relaxed">
//             We’re holding supply drives throughout Fall 2024. Check out our list of most-needed supplies and see how you can make a difference. Donating items like food, toys, or bedding will help the Humane Society more than you know.
//           </p>
//         </div>
//       </div>

//       {/* Section 3: Upcoming Event */}
//       <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
//         <div>
//           <h2 className="text-3xl font-semibold text-gray-800 mb-4">
//             First Supply Drive: October 15th
//           </h2>
//           <p className="text-lg text-gray-700 leading-relaxed">
//             Our first drive will be on October 15th from 2-5 PM. If you’re local, come stop by and drop off donations! If you can’t make it, please consider donating to our GoFundMe page – every bit helps the Humane Society and the animals they care for.
//           </p>
//           <div className="mt-6 text-center">
//             <span className="block text-lg font-semibold text-gray-800 mb-2">Countdown to Event:</span>
//             <span className="text-blue-600 text-2xl font-bold">
//               {timeLeft.days} Days {timeLeft.hours} Hours {timeLeft.minutes} Minutes {timeLeft.seconds} Seconds
//             </span>
//           </div>
//         </div>
//         <div className="rounded-lg shadow-md overflow-hidden">
//           <img
//             src="https://www.hscipets.org/images/event.jpg"
//             alt="Supply Drive Event"
//             className="w-full object-cover h-full"
//           />
//         </div>
//       </div>

//       {/* Section 4: GoFundMe Embed */}
//       <div className="my-16">
//         <iframe 
//           src="https://www.gofundme.com/f/make-a-pawsitive-impact-on-the-humane-society/widget/medium/"
//           width="100%"
//           height="400px"
//           frameBorder="0"
//           scrolling="no"
//           title="GoFundMe"
//           className="rounded-lg shadow-md"
//         ></iframe>
//       </div>
//     </div>
//   );
// }

// export default HomePage;

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CarolinaImage from './Carolina.png';
import SugarPlum from './Sugar-Plum.png';

function HomePage() {
  const calculateTimeLeft = () => {
    const eventDate = new Date('2024-10-23T12:00:00');
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="bg-white min-h-screen p-8">
      <Helmet>
        <script src="https://www.gofundme.com/static/js/widget.js"></script>
      </Helmet>

      <h1 className="text-5xl font-bold text-center text-gray-800 mb-12">
        Welcome to Pawsitive Impact!
      </h1>

      {/* Grid container for the top 2 rows */}
      <div className="grid md:grid-cols-2 gap-8 mb-16">
        {/* Row 1 */}
        <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Who Are We?
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            We're a group of students at ISU working with the Humane Society to support their needs and spread awareness about local pets that need homes. Every donation goes directly to animals in need. Please consider helping in any way you can!
          </p>
        </div>

        <div className="p-6 bg-gray-100 rounded-lg shadow-lg flex items-center space-x-6">
          <div className="rounded-lg shadow-md overflow-hidden w-1/2">
            <img
              src={CarolinaImage}
              alt="Carolina"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="w-1/2">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              Carolina
            </h3>
            <p className="text-gray-700">
              Helloooooo! My name is Carolina and I’m a 3-year-old female hound mix. I’m a very good girl who loves to sing you the song of my people. I enjoy treats, but what I love most is people watching out the window.
            </p>
          </div>
        </div>

        {/* Row 2 */}
        <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            How You Can Help
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            We’re holding supply drives throughout Fall 2024. Check out our list of most-needed supplies and see how you can make a difference. Donating items like food, toys, or bedding will help the Humane Society more than you know.
          </p>
        </div>

        <div className="p-6 bg-gray-100 rounded-lg shadow-lg flex items-center space-x-6">
          <div className="rounded-lg shadow-md overflow-hidden w-1/2">
            <img
              src={SugarPlum}
              alt="Sugar Plum"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="w-1/2">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              Sugar Plum
            </h3>
            <p className="text-gray-700">
              Hello! My name is Sugar Plum and I’m a 2-year-old female tabico shorthair. I’m a sweet girl who is particular about who I hang out with, but I love to explore and get loved on. 
            </p>
          </div>
        </div>
      </div>

      {/* Grid for the Supply Drive and GoFundMe sections */}
      <div className="grid md:grid-cols-2 gap-8 mb-16">
        <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            First Supply Drive: October 15th
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            Our first drive will be on October 23rd from 12-3 PM. If you’re local, come stop by and drop off donations! If you can’t make it, please consider donating to our GoFundMe page – every bit helps the Humane Society and the animals they care for.
          </p>
          <div className="mt-6 text-center">
            <span className="block text-lg font-semibold text-gray-800 mb-2">
              Countdown to Event:
            </span>
            <span className="text-blue-600 text-2xl font-bold">
              {timeLeft.days} Days {timeLeft.hours} Hours {timeLeft.minutes} Minutes {timeLeft.seconds} Seconds
            </span>
          </div>
        </div>

        <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
          <iframe
            src="https://www.gofundme.com/f/make-a-pawsitive-impact-on-the-humane-society/widget/medium/"
            width="100%"
            height="400px"
            frameBorder="0"
            scrolling="no"
            title="GoFundMe"
            className="rounded-lg shadow-lg"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
