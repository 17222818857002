import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="bg-gray-100 shadow-md py-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="ml-4 text-2xl font-bold text-black ">Pawsitive Impact</h1>
        <ul className="flex space-x-4">
          <li><Link to="/" className="text-black hover:text-blue-500">Home</Link></li>
          <li><Link to="/supply-drive" className="text-black hover:text-blue-500">Supply Drive</Link></li>
          <li><Link to="/donate" className="text-black hover:text-blue-500">Donate</Link></li>
          <li><Link to="/contact" className="text-black hover:text-blue-500 mr-2">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
