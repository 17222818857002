// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom'; // Use `useNavigate` instead of `useHistory`

// function ContactPage() {
//   const [formData, setFormData] = useState({ name: '', email: '', message: '' });
//   const [submitted, setSubmitted] = useState(false);
//   const [error, setError] = useState(false);
//   const navigate = useNavigate(); // Use `useNavigate` for navigation

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     fetch('https://formspree.io/f/xqakzold', {  // Replace with your Formspree endpoint
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(formData),
//     })
//     .then((response) => {
//       if (response.ok) {
//         setSubmitted(true);
//         setError(false);
//       } else {
//         throw new Error('Form submission failed');
//       }
//     })
//     .catch((error) => {
//       console.error('Form submission error:', error);
//       setError(true);
//     });
//   };

//   const handleGoHome = () => {
//     navigate('/'); // Use `navigate` instead of `history.push`
//   };

//   if (submitted) {
//     // Render success screen if the form was submitted successfully
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className="text-center">
//           <h1 className="text-3xl font-bold text-green-500">Success!</h1>
//           <p className="mt-4 text-gray-600">Your message has been submitted successfully.</p>
//           <button
//             onClick={handleGoHome}
//             className="mt-6 bg-blue-500 text-white py-2 px-4 rounded"
//           >
//             Back to Homepage
//           </button>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="py-10 max-w-md mx-auto">
//       <h1 className="text-2xl font-bold text-blue-500">Contact Us</h1>
//       <p className="mt-4 text-gray-600">We'd love to hear from you! Please fill out the form below, and we'll get in touch as soon as possible.</p>
//       <form onSubmit={handleSubmit} className="mt-6 space-y-4">
//         <div>
//           <label className="block text-gray-700" htmlFor="name">Name:</label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className="w-full px-4 py-2 border border-gray-300 rounded"
//             required
//           />
//         </div>
//         <div>
//           <label className="block text-gray-700" htmlFor="email">Email:</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="w-full px-4 py-2 border border-gray-300 rounded"
//             required
//           />
//         </div>
//         <div>
//           <label className="block text-gray-700" htmlFor="message">Message:</label>
//           <textarea
//             id="message"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             className="w-full px-4 py-2 border border-gray-300 rounded"
//             rows="5"
//             required
//           ></textarea>
//         </div>
//         <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded">Submit</button>
//       </form>
//       {error && (
//         <div className="mt-4 text-red-500">
//           Oops! Something went wrong. Please try again.
//         </div>
//       )}
//     </div>
//   );
// }

// export default ContactPage;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ContactPage() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://formspree.io/f/xqakzold', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true);
          setError(false);
        } else {
          throw new Error('Form submission failed');
        }
      })
      .catch((error) => {
        console.error('Form submission error:', error);
        setError(true);
      });
  };

  const handleGoHome = () => {
    navigate('/');
  };

  if (submitted) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <div className="text-center bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-green-500">Success!</h1>
          <p className="mt-4 text-gray-600">Your message has been submitted successfully.</p>
          <button
            onClick={handleGoHome}
            className="mt-6 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Back to Homepage
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center">
      <div className="py-10 px-6 bg-white shadow-lg rounded-lg max-w-lg w-full">
        <h1 className="text-3xl font-bold text-black mb-6">Contact Us</h1>
        <p className="mb-6 text-gray-600">We'd love to hear from you! Please fill out the form below, and we'll get in touch as soon as possible.</p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-semibold" htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold" htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold" htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
            Submit
          </button>
        </form>
        {error && (
          <div className="mt-4 text-red-500">
            Oops! Something went wrong. Please try again.
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactPage;

