// import React from 'react';

// function SupplyDrivePage() {
//   const needs = [
//     "Gift cards to Walmart, Target, Meijer, Amazon.com, or Chewy",
//     "Liquid laundry detergent",
//     "Bleach",
//     "Liquid dish soap",
//     "Paper towels",
//     "Paper-based small animal bedding (Carefresh)",
//     "Garbage bags: 30 gallon or larger",
//     "Drum liners: 55 gallon",
//     "Cat litter (non-clumping, unscented)",
//     "Cat scratch pads",
//     "Liquid hand soap",
//     "2-ply toilet tissue",
//     "White 8½” x 11″ copy paper",
//     "Pastel 8½” x 11″ copy paper",
//     "Laminating pouches",
//     "Martingale dog collars",
//     "Paper folders with two pockets"
//   ];

//   const supplyDriveDates = [
//     { date: "Tuesday, Oct 15", time: "2 PM - 5 PM" },
//     { date: "Wednesday, Oct 23", time: "12 PM - 3 PM" },
//     { date: "Friday, Oct 25", time: "12 PM - 3 PM" }
//   ];

//   return (
//     <div className="py-10 max-w-4xl mx-auto px-4">
//       <h1 className="text-3xl font-bold text-blue-600 text-center mb-6">Current Needs for the Supply Drive</h1>

//       <p className="text-lg text-gray-800 text-center mb-4">
//         Join us in supporting our local animal shelter! We will be accepting cash donations and online contributions through our GoFundMe.
//       </p>

//       <h2 className="text-2xl font-semibold text-blue-500 text-center my-6">Donation Dates</h2>
//       <ul className="space-y-4">
//         {supplyDriveDates.map((drive, index) => (
//           <li key={index} className="bg-blue-100 p-4 rounded shadow-md text-gray-700 text-center">
//             <strong>{drive.date}</strong> | {drive.time}
//           </li>
//         ))}
//       </ul>

//       <h2 className="text-2xl font-semibold text-blue-500 text-center my-6">Supplies Needed</h2>
//       <ul className="mt-4 space-y-2">
//         {needs.map((item, index) => (
//           <li key={index} className="text-gray-700 text-center">
//             {item}
//           </li>
//         ))}
//       </ul>

//       <div className="text-center mt-8">
//         <p className="text-lg text-gray-800 mb-4">
//           Additionally, during our second supply drive on <strong>Wednesday, Oct 23</strong>, we will have a pop-up sale for Double Good popcorn!
//         </p>
//         <p className="text-lg text-gray-800 mb-4">
//           Stay tuned for more details on this delicious opportunity!
//         </p>
//       </div>

//       <div className="mt-8 text-center">
//         <h3 className="text-xl font-semibold text-blue-500 mb-2">How to Donate:</h3>
//         <p className="text-gray-700 mb-4">We accept both in-person donations and online contributions. Click below to donate online!</p>
//         <a 
//           href="https://www.gofundme.com" // Replace with your actual GoFundMe link
//           target="_blank"
//           rel="noopener noreferrer"
//           className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 transition duration-300"
//         >
//           Donate Now
//         </a>
//       </div>
//     </div>
//   );
// }

// export default SupplyDrivePage;

import React from 'react';

function SupplyDrivePage() {
  const needs = [
    "Gift cards to Walmart, Target, Meijer, Amazon.com, or Chewy",
    "Liquid laundry detergent",
    "Bleach",
    "Liquid dish soap",
    "Paper towels",
    "Paper-based small animal bedding (Carefresh)",
    "Garbage bags: 30 gallon or larger",
    "Drum liners: 55 gallon",
    "Cat litter (non-clumping, unscented)",
    "Cat scratch pads",
    "Liquid hand soap",
    "2-ply toilet tissue",
    "White 8½” x 11″ copy paper",
    "Pastel 8½” x 11″ copy paper",
    "Laminating pouches",
    "Martingale dog collars",
    "Paper folders with two pockets"
  ];

  const supplyDriveDates = [
    { date: "Wednesday, Oct 23", time: "12 PM - 3 PM" },
    { date: "Friday, Oct 25", time: "12 PM - 3 PM" }
  ];

  return (
    <div className="py-10 max-w-6xl mx-auto px-4">
      {/* Title */}
      <h1 className="text-5xl font-bold text-gray-900 text-center mb-12">
        Current Needs for the Supply Drive
      </h1>

      <div className="grid lg:grid-cols-3 gap-10">
        {/* Sidebar for Supply Drive Dates */}
        <div className="lg:col-span-1 bg-gray-100 p-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
            Donation Dates
          </h2>
          <ul className="space-y-6">
            {supplyDriveDates.map((drive, index) => (
              <li key={index} className="bg-white p-4 rounded-lg shadow text-center">
                <strong className="block text-lg text-gray-700">{drive.date}</strong>
                <span className="text-gray-600">{drive.time}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Main Section for Supplies */}
        <div className="lg:col-span-2">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6">
            Supplies Needed
          </h2>
          <ul className="grid md:grid-cols-2 gap-6">
            {needs.map((item, index) => (
              <li key={index} className="bg-gray-50 p-4 rounded-lg shadow text-gray-700">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Additional Info */}
      <div className="mt-16 text-center">
        <p className="text-lg text-gray-800 mb-4">
          During our second supply drive on <strong>Wednesday, Oct 23</strong>, we’ll also have a pop-up sale for Double Good popcorn!
        </p>
        <p className="text-lg text-gray-800">
          Stay tuned for more details on this delicious opportunity!
        </p>
      </div>
    </div>
  );
}

export default SupplyDrivePage;
